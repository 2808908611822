<template>
  <div class="contact-view">
    <h3 class="mb-4 font-weight-bold">
      KONTAKT
    </h3>
    <b-row class="justify-content-around">
      <b-col cols="auto" md="3" lg="4" class="text-left mb-4 column">
        <div class="title mb-4">Godziny otwarcia</div>
        <ul class="list-unstyled">
          <li><div class="mb-2">Poniedziałek - Piątek</div></li>
          <li><div class="mb-3">8:00-16:00</div></li>
          <li><div class="mb-2">Sobota</div></li>
          <li><div class="mb-2">9:00-12:00</div></li>
        </ul>
      </b-col>
      <b-col cols="auto" md="5" lg="4" class="text-left mb-4 column">
        <div class="title mb-1">Ubezpieczenia</div>
        <div class="title mb-3">Mateusz Karbowiecki</div>
        <div class="mb-1">ul. Solny Rynek 4</div>
        <div class="mb-3">46-300 Olesno</div>

        <a href="tel:606201079"><div class="mb-2">Tel. 606 201 079</div></a>
        <a href="tel:345000087"><div class="mb-3">Tel. 345 000 087</div></a>
        <a href = "mailto: biuro@ubezpieczenia-olesno.pl"><div class="mb-3">biuro@ubezpieczenia&#8209;olesno.pl</div></a>
      </b-col>
      <b-col lg="4" class="mb-4 column">
        <localization-map/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LocalizationMap from "@/components/LocalizationMap.vue";

export default {
  name: "ContactView",
  components: {LocalizationMap}
}
</script>

<style scoped lang="scss">
.contact-view {
  color: white;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;

  a{
    color: white;
    text-decoration: underline !important;
  }

  .title {
    font-size: 21px;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  @media (max-width: 720px) {
   .column{
     width: 100%;
     text-align: center !important;
   }
  }
}
</style>

<template>
  <div class="download-document">
    <div class="title">{{title}}</div>
    <b-button variant="primary"
              class="download-button company_color_as_background"
              :href="documentSrc"
              :disabled="typeof documentSrc != 'string' || documentSrc === ''"
              download
    >POBIERZ<b-icon class="download-icon "
                    icon="download"
                    aria-hidden="true"
    />
    </b-button>
  </div>
</template>

<script>
export default {
  name: "DownloadDocument",
  props: {
    documentSrc: String,
    title: String,
  }
}
</script>

<style scoped lang="scss">
.download-document {
  margin-bottom: 30px;

  .title{
    font-size: 20px;
    margin-bottom: 0.5rem;
  }

  .download-button{
    border-color: transparent;

    .download-icon{
      margin-left: 10px;
    }
  }
}
</style>

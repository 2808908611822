<template>
  <div class="localization-map">
    <div class="loading-wrapper" v-if="!iframeLoaded">
      <loader/>
      <p class="mt-1">Wczytywanie mapy Google</p>
    </div>

    <div
        ref="googleMapLazyContainer"
        class="lazyframe"
        :class="{'invisible': !iframeLoaded}"
        data-vendor=""
        data-title="Google Maps"
        data-thumbnail=""
        data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.541882768369!2d18.416763215363993!3d50.876680379536445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710ef8e5bea66b1%3A0x12175c206feee40!2sUbezpieczenia%20Mateusz%20Karbowiecki!5e0!3m2!1spl!2spl!4v1666541493051!5m2!1spl!2spl"
        data-initinview="false"
        data-autoplay="false"
        style="border:0;"
    ></div>
  </div>
</template>

<script>
import lazyframe from "lazyframe";
import Loader from "@/components/Loader.vue";


export default {
  name: "LocalizationMap",
  components: {Loader},
  data() {
    return {
      iframeLoaded: false
    }
  },
  methods: {
    loadIframe(){
      lazyframe([this.$refs.googleMapLazyContainer], {
        debounce: 250,
        // Callbacks
        onAppend: (frame) => {
          frame.title = "Google Maps"
          this.iframeLoaded = true;
        },
        // onLoad: (iframe) => console.log(iframe),
        // onThumbnailLoad: (img) => console.log("thum"),
      });
    }
  },
  mounted() {
    this.loadIframe();
  }
}
</script>

<style scoped lang="scss">
.localization-map {
  overflow: hidden;
  position: relative;
  aspect-ratio: 1 / 0.7;

  .loading-wrapper, .lazyframe {
    width: 100%;
    height: 100%;
  }

  .loading-wrapper {
    border: 1px solid #ffffff6b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lazyframe {
    &.invisible {
      width: 0;
      height: 0;
      opacity: 1;
    }
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import './assets/scss/main.scss'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCar, faHouse, faHeart, faTractor, faBuilding, faHandshake } from '@fortawesome/free-solid-svg-icons'
// import { faAirbnb } from '@fortawesome/free-brands-svg-icons'
// import { faAngry } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(faCar)
library.add(faHouse)
library.add(faHeart)
library.add(faTractor)
library.add(faBuilding)
library.add(faHandshake)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

new Vue({
  // router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

<template>
  <div id="app">
    <section class="section section1-bg">
      <b-container>
        <banner-view/>
      </b-container>
    </section>
    <section :id="getKeyOfSectionOfId(0)"
             class="section section2-bg">
      <b-container>
        <offer-view/>
      </b-container>
    </section>
    <section :id="getKeyOfSectionOfId(1)"
             class="section background_color_2">
      <b-container>
        <files-view/>
      </b-container>
    </section>
    <footer :id="getKeyOfSectionOfId(2)"
            class="section footer_color footer">
      <b-container>
        <contact-view/>
      </b-container>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import OfferView from "@/views/OfferView.vue";
import BannerView from "@/views/BannerView.vue";
import ContactView from "@/views/ContactView.vue";
import FilesView from "@/views/FilesView.vue";

export default {
  name: 'App',
  components: {FilesView, ContactView, BannerView, OfferView, Navbar},
  data() {
    return {
      navbarSections: {
        section2: "Oferta",
        section3: "Pliki",
        section4: "Kontakt"
      }
    }
  },
  methods: {
    getKeyOfSectionOfId(sectionId) {
      return Object.keys(this.navbarSections)[sectionId];
    },
  },
}
</script>

<style lang="scss">

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: linear-gradient(0deg, #27282a 50%, white 50%);
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

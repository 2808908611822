<template>
  <div class="files-view">
    <h3 class="mb-4 font-weight-bold">
      DO POBRANIA
    </h3>
    <div class="row align-items-center justify-content-center">
      <b-col lg="4" sm="6" v-for="(fileToDownload, key) in filesToDownload" :key="`file-to-download-${key}`">
        <download-document :title="fileToDownload.title"
                           :documentSrc="fileToDownload.src"
        />
      </b-col>
    </div>
  </div>
</template>

<script>
import DownloadDocument from "@/components/DownloadDocument.vue";

export default {
  name: "FilesView",
  components: {
    DownloadDocument,
  },
  data() {
    return {
      filesToDownload: [
        {
          title: "Oświadczenie sprawcy",
          src: "./zalaczniki/01_Oswiadczenie_sprawcy_kolizji_drogowej.pdf",
        },
        {
          title: "Umowa kupna-sprzedaży",
          src: "./zalaczniki/02_Umowa_kupna-sprzedazy_pojazdu.pdf",
        },
        {
          title: "Umowa darowizny",
          src: "./zalaczniki/03_Umowa_darowizny.pdf",
        },
        {
          title: "Wypowiedzenie OC",
          src: "./zalaczniki/04_Wypowiedzenie_OC.pdf",
        },
        {
          title: "Wypowiedzenie OC Nabywcy",
          src: "./zalaczniki/05_Wypowiedzenie_OC_przez_nabywce.pdf",
        },
        {
          title: "Zwrot składki",
          src: "./zalaczniki/06_Zwrot-skladki.pdf"
        },
      ]
    }
  },
}
</script>

<style scoped lang="scss">
.files-view {
}
</style>

<template>
  <div class="offer-view">
    <div class="wrapper row align-items-center justify-content-center">
      <b-col lg="2" md="4" sm="6" cols="6" class="box-wrapper" v-for="(offerItem, key) in offerItems"
             :key="`offer-item-${key}`">
        <wc-box :title="offerItem.title"
                :iconClass="offerItem.iconClass"
        />
      </b-col>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WcBox from "@/components/WcBox.vue";

export default {
  name: 'OfferView',
  components: {
    WcBox,
  },
  data() {
    return {
      offerItems: [
        {
          title: "Komunikacyjne",
          iconClass: "fa-solid fa-car"
        },
        {
          title: "Majątkowe",
          iconClass: "fa-solid fa-house"
        },
        {
          title: "Na życie",
          iconClass: "fa-solid fa-heart"
        },
        {
          title: "Rolne",
          iconClass: "fa-solid fa-tractor"
        },
        {
          title: "Dla firm",
          iconClass: "fa-solid fa-building"
        },
        {
          title: "Gwarancje",
          iconClass: "fa-solid fa-handshake"
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.offer-view {
  padding: 0 10px;
  position: relative;
  display: flex;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 100vw;

    @media (max-width: 767px) {
      display: flex;
      justify-content: space-around;
    }

    @media (max-width: 375px){
      max-width: 248px;
    }

    @media (min-width: 375px) and (max-width: 500px) {
      max-width: 325px;
    }

    @media (min-width: 501px) and (max-width: 767px) {
      max-width: 420px;
    }

  }

  @media (max-width: 374px) {
    .wrapper {
      .box-wrapper {
        //flex: 0 0 100%;
        //max-width: 100% !important;
        .wc-box {
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;

          .wc-box-icon {
            font-size: 3rem;
            height: calc(6rem);
            width: calc(6rem);
          }
        }
      }
    }
  }

  @media (min-width: 375px) and (max-width: 767px) {
    .wrapper {
      .box-wrapper {
        display: flex;
        justify-content: center;
        //flex: 0 0 100%;
        //max-width: 100% !important;
        .wc-box {
          max-width: 220px;
        }
      }
    }
  }

  @media (min-width: 375px) and (max-width: 767px) {
    .wrapper {
      .box-wrapper {
        padding-left: 5px;
        padding-right: 5px;

        .wc-box {
          margin-bottom: 5px;
          padding: 0.5rem !important;

          .wc-box-icon {
            img {
              height: 100px;
            }
          }

          .wc-box-title {
            margin-top: 0.75rem !important;
          }
        }
      }
    }
  }
}
</style>

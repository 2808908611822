<template>
  <div>
    <b-navbar toggleable="lg" fixed="top" class="navbar-custom" v-bind:class="{scrolled:windowScrolled}">
      <b-container>
        <!--        <b-navbar-brand href="#">-->
        <!--&lt;!&ndash;          <img src="https://placekitten.com/g/30/30" class="d-inline-block align-top" alt="Kitten">&ndash;&gt;-->
        <!--          <div class="company-info">-->
        <!--            <span>Ubezpieczenia</span>-->
        <!--            <span>Mateusz Karbowiecki</span>-->
        <!--          </div>-->
        <!--        </b-navbar-brand>-->

        <b-navbar-toggle target="nav-collapse" title="Menu" class="ml-auto">
          <template #default="{ expanded }">
            <div class="h3 mb-0 menu-icon company_color">
              <b-icon v-if="expanded" icon="x-circle"></b-icon>
              <b-icon v-else icon="list"></b-icon>
            </div>
          </template>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-b-scrollspy="{element: 'body', offset: 130}"
                        class="mx-auto">
            <b-nav-item v-for="(title, sectionId) in sections"
                        :active="sectionId === currentScrollSpyElementId"
                        :key="`navb-item-${sectionId}`"
                        :href="`#${sectionId}`"
            >{{ title }}
            </b-nav-item>
          </b-navbar-nav>

          <!--          <b-navbar-nav class="ml-auto">-->
          <!--          </b-navbar-nav>-->

          <!-- Right aligned nav items -->
          <!--        <b-navbar-nav class="ml-auto">-->
          <!--          <b-nav-form>-->
          <!--            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>-->
          <!--            <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>-->
          <!--          </b-nav-form>-->

          <!--          <b-nav-item-dropdown text="Lang" right>-->
          <!--            <b-dropdown-item href="#">EN</b-dropdown-item>-->
          <!--            <b-dropdown-item href="#">ES</b-dropdown-item>-->
          <!--            <b-dropdown-item href="#">RU</b-dropdown-item>-->
          <!--            <b-dropdown-item href="#">FA</b-dropdown-item>-->
          <!--          </b-nav-item-dropdown>-->

          <!--          <b-nav-item-dropdown right>-->
          <!--            &lt;!&ndash; Using 'button-content' slot &ndash;&gt;-->
          <!--            <template #button-content>-->
          <!--              <em>User</em>-->
          <!--            </template>-->
          <!--            <b-dropdown-item href="#">Profile</b-dropdown-item>-->
          <!--            <b-dropdown-item href="#">Sign Out</b-dropdown-item>-->
          <!--          </b-nav-item-dropdown>-->
          <!--        </b-navbar-nav>-->
        </b-collapse>
      </b-container>
    </b-navbar>
    <!--  <div class="navbar navbar-custom navbar-expanded" v-bind:class="{scrolled:windowScrolled}">-->
    <!--    <ul v-show="sections" class="nav-list mx-auto">-->
    <!--      <li v-for="(title, sectionId) in sections"-->
    <!--          class="nav-item"-->
    <!--      >-->
    <!--        <a href="">-->
    <!--          {{ title }}-->
    <!--        </a>-->
    <!--      </li>-->
    <!--    </ul>-->
    <!--  </div>-->
  </div>
</template>

<script>

export default {
  name: "Navbar",
  props: {
    sections: Object,
  },
  data() {
    return {
      windowScrolled: false,
      currentScrollSpyElementId: null,
    }
  },
  methods: {
    handleScroll() {
      this.windowScrolled = window.scrollY;
    },
    onActivateScrollspy(target) {
      console.log('Received event: "bv::scrollspy::activate" for target ', target)
      this.currentScrollSpyElementId = target;
    }
  },
  mounted() {
    this.handleScroll();
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.$root.$on('bv::scrollspy::activate', this.onActivateScrollspy);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped lang="scss">
.navbar-custom {
  background-color: transparent;
  transition: all .3s ease-in-out;
  z-index: 999;
  padding: 14px 0;

  &.scrolled {
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 9%);
  }

  @media (max-width: 991px) {
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 9%);
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-brand {
    display: flex;

    .company-info {
      margin-left: 20px;
      display: flex;
      flex-direction: column;

      span {
        &:first-child {
          font-size: 16px;
          line-height: 16px;
        }

        &:last-child {
          font-size: 12px;
        }
      }
    }
  }

  .navbar-toggler {
    border: none;

    span {
      color: #0D81BC;
    }

    .menu-icon {
      line-height: 1;
      font-size: 1.99rem;
    }
  }

  .collapsing {
    transition: none;
  }

  .navbar-nav {
    .nav-item {
      a {
        font-size: 16px;
        font-weight: 600;
        color: #292c3b;
        margin: 0 20px;
      }

      &.active {
        a {
          color: #0D81BC;
        }
      }
    }
  }
}
</style>

<template>
  <div class="wc-box rounded text-center wc-box-primary p-2">
    <div class="wc-box-icon company_color_as_background">
      <font-awesome-icon :icon="iconClass" />
    </div>
    <h5 class="wc-box-title mt-3 font-weight">
      {{ title }}
    </h5>
  </div>
</template>

<script>
export default {
  name: "WcBox",
  props: {
    iconClass: String,
    title: String,
  },
}
</script>

<style scoped lang="scss">
.wc-box {
  position: relative;
  //box-shadow: 0 3px 12px rgb(0 0 0 / 9%);
  //margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .wc-box-icon {
    font-size: 5rem;
    height: calc(8rem);
    width: calc(8rem );
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .wc-box-title {
    font-size: 20px;

    @media (max-width: 374px){
      font-size: 14px;
    }
  }
}
</style>

<template>
  <div class="banner-view row align-items-center justify-content-center">
    <div class="col-lg-10">
      <img src="@/assets/logo_firmy.png" alt="Ubezpieczenia Mateusz Karbowiecki" width="851" height="158"/>
<!--      <h1 class="text-dark text-shadow mb-1">UBEZPIECZENIA</h1>-->
<!--      <h2 class="text-dark text-shadow mb-4">Mateusz Karbowiecki</h2>-->
    </div>
  </div>
</template>

<script>

export default {
  name: 'BannerView',
}
</script>

<style scoped lang="scss">
.banner-view {
  img{
    width: 90%;
    height: auto;
    padding-bottom: 2rem;
  }
}
</style>
